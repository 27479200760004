/**
 * Used for validation of postal codes based on country codes
 * Please, consider that this helper is used on frontend(/js/validator/postal) as well as on backend(OCAPI calls)
 */
var postalRegEx = {
    XN: /^([A-Z])\w+([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,

    GB: /^([A-Z])\w+([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
    US: /^\d{5}(-\d{4})?$/,
    CA: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1}([ ]|)*\d{1}[A-Z]{1}\d{1}$/,

    FR: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
    IT: /^([0-9]){5}$/,
    JP: /^([0-9]){3}[-]([0-9]){4}$/,
    CN: /^([0-9]){6}$/,

    AT: /^[0-9]{4}$/,
    BE: /^[0-9]{4}$/,
    BG: /^[0-9]{4}$/,
    CY: /^[0-9]{4}$/,
    CZ: /^([0-9]{5}(\s)?)$|^([0-9]{3}\s[0-9]{2}(\s)?)$/,
    DK: /^[0-9]{4}$/,
    DE: /^[0-9]{5}$/,
    EE: /^[0-9]{5}$/,
    ES: /^[0-9]{5}$/,
    FI: /^[0-9]{5}$/,
    HU: /^[0-9]{4}$/,
    HR: /^[0-9]{5}$/,
    LV: /^([Ll][Vv][- ]{0,1})?\d{4}$/,
    LT: /^([Ll][Tt][- ]{0,1})?\d{5}$/,
    LU: /^[0-9]{4}$/,
    NL: /^\d{4}\s{0,1}([A-Za-z]{2})?$/,
    PL: /^\d{2}[- ]{0,1}\d{3}$/,
    PT: /^\d{4}[- ]{0,1}\d{3}$/,
    RO: /^[0-9]{6}$/,
    SE: /^[0-9]{3}\s*[0-9]{2}$/,
    SI: /^([Ss][Ii][-/]{0,1}){0,1}[0-9]{4}$/,
    SK: /^[0-9]{5}\s{0,1}|([0-9]{3}\s[0-9]{2}\s{0,1})$/
};


module.exports = function (country, postal) {
    let isValid = false;
    if (country in postalRegEx) {
        const rgx = postalRegEx[country];
        isValid = rgx.test(postal);
    } else {
        // if we haven't RegEx, it valid by default
        isValid = true;
    }

    return isValid;
};
